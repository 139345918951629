import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { navigate } from "gatsby";
import { Stack } from "@mui/material";

const content = {
  margin: "20px",
};

const shareButton = {
  width: "100px",
  marginTop: "5px",
};

function onGoToHomePage() {
  navigate("/");
}

const NotFoundPage = (): JSX.Element => (
  <Stack sx={content} direction="column" spacing={2}>
    <Typography variant="h5">Page Not Found</Typography>
    <Button variant="contained" color="primary" sx={shareButton} type="submit" onClick={onGoToHomePage}>
      &nbsp;Home&nbsp;
    </Button>
  </Stack>
);

export default NotFoundPage;
